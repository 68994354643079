import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['menu']

  connect() {
    this.element.classList.add('relative')
    document.addEventListener('click', this.handleClickOutside.bind(this))
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this))
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.hide()
    }
  }

  hide() {
    const HIDE_DELAY = 75
    const menu = this.menuTarget
    // Leaving state
    menu.classList.remove('opacity-100', 'scale-100')
    menu.classList.add('opacity-0', 'scale-95')

    setTimeout(() => {
      menu.classList.add('hidden')
    }, HIDE_DELAY)
  }

  toggle() {
    const menu = this.menuTarget
    const isHidden = menu.classList.contains('hidden')

    if (isHidden) {
      menu.classList.remove('hidden', 'opacity-0', 'scale-95')
      menu.classList.add('opacity-100', 'scale-100')
    } else {
      this.hide()
    }
  }
}
