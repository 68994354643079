import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fileInput', 'fileNameDisplay', 'dropZone', 'submitButton']
  static values = {
    requiredTypes: Array,
    uploadedTypes: { type: Array, default: [] },
  }

  connect() {
    this.dragCounter = 0
    this.updateSubmitButton()
  }

  handleDragEnter(e) {
    e.preventDefault()
    this.dragCounter++
    this.updateDropZoneStyle(e.currentTarget, true)
  }

  handleDragLeave(e) {
    e.preventDefault()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.updateDropZoneStyle(e.currentTarget, false)
    }
  }

  handleDragOver(e) {
    e.preventDefault()
  }

  handleDrop(e) {
    e.preventDefault()
    e.stopPropagation()
    this.updateDropZoneStyle(e.currentTarget, false)
    this.dragCounter = 0
    const file = e.dataTransfer.files[0]
    if (file && this.isAllowedFileType(file, e.currentTarget)) {
      const fileInput = e.currentTarget.querySelector('input[type="file"]')
      fileInput.files = e.dataTransfer.files
      this.updateFileName(fileInput)
    } else {
      this.showErrorMessage(
        'Invalid file type. Please upload the correct file type.',
        e.currentTarget,
      )
    }
  }

  updateFileName(input) {
    const fileInput = input instanceof Event ? input.target : input
    const file = fileInput.files[0]
    const fileNameDisplay = fileInput
      .closest('label')
      .querySelector('[data-file-upload-target="fileNameDisplay"]')
    const fileType = fileInput.closest('[data-file-type]').dataset.fileType

    if (file) {
      if (this.isAllowedFileType(file, fileInput.closest('label'))) {
        const fileName = file.name
        const fileSize = this.formatFileSize(file.size)
        fileNameDisplay.textContent = `${fileName} (${fileSize})`
        fileNameDisplay.classList.remove('text-red-500')

        // Update the uploadedTypes array
        if (!this.uploadedTypesValue.includes(fileType)) {
          this.uploadedTypesValue = [...this.uploadedTypesValue, fileType]
        }
      } else {
        this.showErrorMessage(
          'Invalid file type. Please upload the correct file type.',
          fileInput.closest('label'),
        )
        fileInput.value = '' // Clear the input
        // Remove the file type if it was previously uploaded
        this.uploadedTypesValue = this.uploadedTypesValue.filter(
          type => type !== fileType,
        )
      }
    } else {
      fileNameDisplay.textContent = 'No file chosen'
      fileNameDisplay.classList.remove('text-red-500')
      // Remove the file type from the array of uploaded file types
      this.uploadedTypesValue = this.uploadedTypesValue.filter(
        type => type !== fileType,
      )
    }

    this.updateSubmitButton()
  }

  updateSubmitButton() {
    if (this.hasSubmitButtonTarget) {
      const allTypesUploaded = this.requiredTypesValue.every(type =>
        this.uploadedTypesValue.includes(type),
      )
      this.submitButtonTarget.disabled = !allTypesUploaded
    }
  }

  isAllowedFileType(file, element) {
    const allowedTypes = element
      .querySelector('input[type="file"]')
      .dataset.allowedTypes.split(',')
    const fileExtension = '.' + file.name.split('.').pop().toLowerCase()
    return allowedTypes.includes(fileExtension)
  }

  showErrorMessage(message, element) {
    const fileNameDisplay = element.querySelector(
      '[data-file-upload-target="fileNameDisplay"]',
    )
    fileNameDisplay.textContent = message
    fileNameDisplay.classList.add('text-red-500')
  }

  updateDropZoneStyle(element, isDragging) {
    const dropZone = element.querySelector(
      '[data-file-upload-target="dropZone"]',
    )
    if (isDragging) {
      dropZone.classList.remove('bg-gray-100')
      dropZone.classList.add('bg-blue-100')
    } else {
      dropZone.classList.remove('bg-blue-100')
      dropZone.classList.add('bg-gray-100')
    }
  }

  formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
  }
}
