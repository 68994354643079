import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="element-removal"
export default class extends Controller {
  static targets = ['notificationPanel']
  static values = {
    timeOutDuration: { type: Number, default: 5000 },
    transitionDuration: { type: Number, default: 300 },
  }

  connect() {
    setTimeout(() => {
      this.notificationPanelTarget.classList.remove(
        'translate-y-2',
        'opacity-0',
        'sm:translate-y-0',
        'sm:translate-x-2',
      )
      this.notificationPanelTarget.classList.add(
        'translate-y-0',
        'opacity-100',
        'sm:translate-x-0',
      )
    }, 10)

    this.timeout = setTimeout(() => {
      this.startRemoval()
    }, this.timeOutDurationValue)
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  startRemoval() {
    this.notificationPanelTarget.classList.remove('ease-out', 'duration-300')
    this.notificationPanelTarget.classList.add('ease-in', 'duration-100')
    this.notificationPanelTarget.classList.remove('opacity-100')
    this.notificationPanelTarget.classList.add('opacity-0')

    setTimeout(() => {
      this.remove()
    }, this.transitionDurationValue)
  }

  remove() {
    this.element.remove()
  }
}
