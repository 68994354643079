import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['operator', 'value']

  inputConfigs = {
    gender: {
      type: 'select',
      options: [
        { value: '', text: 'Select gender...' },
        { value: 'male', text: 'Male' },
        { value: 'female', text: 'Female' },
      ],
      className:
        'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
    },
    category_type: {
      type: 'select',
      options: [
        { value: '', text: 'Select owner type...' },
        { value: 'individual', text: 'Individual' },
        { value: 'corporate', text: 'Corporate' },
      ],
      className:
        'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
    },
    ownership_type: {
      type: 'select',
      options: [
        { value: '', text: 'Select owner type...' },
        { value: 'co-owned', text: 'Co-owned' },
        { value: 'joint', text: 'Joint' },
        { value: 'single', text: 'Single' },
      ],
      className:
        'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
    },
    registration_date: {
      type: 'date',
      className:
        'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
    },
    default: {
      type: 'text',
      placeholder: 'Enter search value...',
      maxLength: 255,
      className:
        'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
    },
    issue_date: {
      type: 'date',
      className:
        'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
    },
  }

  isDateSearchAttribute(attribute) {
    return ['registration_date', 'issue_date'].includes(attribute)
  }

  attributeChanged(event) {
    const selectedAttribute = event.target.value
    const operatorDiv = this.operatorTarget
    const valueTarget = this.valueTarget

    valueTarget.value = ''

    if (this.isDateSearchAttribute(selectedAttribute)) {
      operatorDiv.classList.remove('hidden')
    } else {
      operatorDiv.classList.add('hidden')
    }

    const config =
      this.inputConfigs[selectedAttribute] || this.inputConfigs.default
    const newInput = this.createInputElement(config)

    const valueContainer = valueTarget.parentElement
    valueContainer.replaceChild(newInput, valueTarget)
    this.valueTarget = newInput
  }

  createInputElement(config) {
    const input =
      config.type === 'select'
        ? this.createSelectInput(config)
        : this.createBasicInput(config)

    Object.assign(input, {
      id: 'value',
      name: 'value',
      className: config.className,
    })

    input.dataset.searchFieldTarget = 'value'

    return input
  }

  createSelectInput(config) {
    const select = document.createElement('select')

    config.options.forEach(option => {
      const optionElement = document.createElement('option')
      optionElement.value = option.value
      optionElement.text = option.text
      select.appendChild(optionElement)
    })

    return select
  }

  createBasicInput(config) {
    const input = document.createElement('input')
    input.type = config.type

    if (config.placeholder) input.placeholder = config.placeholder
    if (config.maxLength) input.maxLength = config.maxLength

    return input
  }
}
