import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['overlay', 'container']

  connect() {
    if (this.element.open) return
    else this.element.showModal()
  }

  close() {
    this.element.close()
  }

  clickOutside(event) {
    if (!this.containerTarget.contains(event.target)) {
      this.close()
    }
  }

  closeWithKeyboard(event) {
    if (event.key === 'Escape') {
      this.close()
    }
  }

  get overlay() {
    return this.overlayTarget
  }

  get container() {
    return this.containerTarget
  }
}
