import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="list-panel"
export default class extends Controller {
  static targets = ['actionPanel']

  connect() {
    document.addEventListener('click', this.handleClickOutside.bind(this))
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this))
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.hidePanel()
    }
  }

  toggle() {
    const panel = this.actionPanelTarget
    if (panel.classList.contains('hidden')) {
      this.showPanel()
    } else {
      this.hidePanel()
    }
  }

  hidePanel() {
    this.actionPanelTarget.classList.remove('ease-out', 'duration-100')
    this.actionPanelTarget.classList.add('ease-in', 'duration-75')
    this.actionPanelTarget.classList.remove('opacity-100')
    this.actionPanelTarget.classList.add('hidden')
  }

  showPanel() {
    this.actionPanelTarget.classList.add('opacity-0')
    this.actionPanelTarget.classList.remove(
      'hidden',
      'transform',
      'opacity-0',
      'scale-95',
    )
    this.actionPanelTarget.classList.add(
      'transform',
      'opacity-100',
      'scale-100',
    )
  }
}
