import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    highlightClass: String,
  }

  connect() {
    if (this.element.classList.contains('slide-in-down')) {
      this.element.addEventListener(
        'animationend',
        this.startHighlight.bind(this),
        { once: true },
      )
    }
  }

  startHighlight() {
    this.element.classList.add(this.highlightClassValue)
    this.element.addEventListener(
      'animationend',
      () => {
        this.element.classList.remove(this.highlightClassValue)
      },
      { once: true },
    )
  }
}
